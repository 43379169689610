import { clsx } from 'clsx';
import Link from '../Link/Link';
import { Typography } from '../Typography';
import styles from './Episode.module.scss';

interface EpisodeProps {
  url: string;
  startDate?: string;
  title: string;
  shortText?: string;
  index: number;
}

export const Episode = ({ url, startDate, title, shortText, index }: EpisodeProps) => {
  console.log(shortText);
  return (
    <Link className={clsx(styles.link, { 'first-teaser': index === 0 })} href={url} target="_blank" noFollow={false}>
      <div className={styles.textWrapper}>
        {startDate && (
          <Typography className={styles.date} variant="4xs" as="div">
            {new Date(startDate).toLocaleDateString('de-DE', {
              weekday: 'short',
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            })}
          </Typography>
        )}
        <Typography className={styles.headline} as="h3" variant={index === 0 ? 's' : '3xs'}>
          {title}
        </Typography>
        <Typography className={styles.description} variant="4xs">
          {shortText}
        </Typography>
      </div>
    </Link>
  );
};
